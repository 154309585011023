// +----------------------------------------------------------------------
// | LikeShop100%开源免费商用电商系统
// +----------------------------------------------------------------------
// | 欢迎阅读学习系统程序代码，建议反馈是我们前进的动力
// | 开源版本可自由商用，可去除界面版权logo
// | 商业版本务必购买商业授权，以免引起法律纠纷
// | 禁止对系统程序代码以任何目的，任何形式的再发布
// | Gitee下载：https://gitee.com/likemarket/likeshopv2
// | 访问官网：https://www.likemarket.net
// | 访问社区：https://home.likemarket.net
// | 访问手册：http://doc.likemarket.net
// | 微信公众号：好象科技
// | 好象科技开发团队 版权所有 拥有最终解释权
// +----------------------------------------------------------------------
// | Author: LikeShopTeam
// +----------------------------------------------------------------------



<template>
    <div class="user-order">
        <van-tabs :active="active" line-width="40" swipe-threshold="5" @change="onChange">
            <van-tab v-for="(item, index) in order" :title="item.name" :name="item.type" :key="index" >
                <order-list v-if="item.isShow" :order-type="item.type" :id="item.type"></order-list>
            </van-tab>
        </van-tabs>
    </div>
</template>

<script>
import OrderList from "@C/OrderList";
import { orderType } from "@/utils/type";
export default {
    name: "userOrder",
    components: {
        OrderList,
    },
    data() {
        return {
            order: [
                {
                    name: "全部",
                    type: orderType.ALL,
                    isShow: false,
                },
                {
                    name: "待付款",
                    type: orderType.PAY,
                    isShow: false,
                },
                {
                    name: "待收货",
                    type: orderType.DELIVERY,
                    isShow: false,
                },
                {
                    name: "已完成",
                    type: orderType.FINISH,
                    isShow: false,
                },
                {
                    name: "已关闭",
                    type: orderType.CLOSE,
                    isShow: false,
                },
            ],
            active: orderType.ALL,
        };
    },
    created() {
        let type = this.$route.query.type || orderType.ALL
		this.changeShow(type)
    },
    mounted() {},
    methods: {
        changeShow(type) {
            const { order } = this
            let index = order.findIndex((item) => {
                return item.type == type;
            });
            if (index != -1) {
                this.active = type
                this.order[index].isShow = true
            }
        },
        onChange(e) {
            this.changeShow(e)
        }
    },
};
</script>

<style lang="scss" scoped>
</style>